




























































































.wjmm {
	display: flex;
	justify-content: flex-end;
	margin-top: 0.26rem;

	span {
		font-size: 0.24rem;
		// font-family: PingFang SC;
		color: #999999;
	}
}
.login-dex1 {
	margin-top: 0.55rem;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;